import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router";

import NavItem from "../../../components/NavItem";
import { DocIcon, LogoutIcon } from "@edenlabllc/ehealth-icons";
import { Link as ExternalLink } from "@edenlabllc/ehealth-components";

import ShowWithScope from "../ShowWithScope";
import ShowMore from "../ShowMore";

import { logOut } from "./redux";
import env from "../../../env";
import flags, { FlagsProvider, Flag } from "../../../flags";

import styles from "./styles.module.css";

class Nav extends React.Component {
  componentWillReceiveProps(props) {
    if (props.isOpen) {
      document.documentElement.classList.add(styles.navIsOpen);
    } else {
      document.documentElement.classList.remove(styles.navIsOpen);
    }
  }
  render() {
    const { isOpen } = this.props;

    return (
      <FlagsProvider flags={flags}>
        <nav className={classnames(styles.nav, isOpen && styles.open)}>
          <ul>
            <Flag name={["features", "legacy"]}>
              <ShowWithScope scope="person:read">
                <NavItem to="/persons" activeClassName={styles.active}>
                  <Link id="persons-nav" to="/persons">
                    Персони
                  </Link>
                </NavItem>
              </ShowWithScope>

              <ShowWithScope scope="person:resetAuthenticationMethod">
                <NavItem
                  to="reset-authentication-method"
                  activeClassName={styles.active}
                >
                  <Link
                    id="reset-authentication-method-nav"
                    to="/reset-authentication-method"
                  >
                    Скинути метод
                    <br />
                    авторизації
                  </Link>
                </NavItem>
              </ShowWithScope>

              <ShowWithScope scope="declaration:read">
                <li>
                  <ShowMore nav name="Декларації">
                    <ul>
                      <NavItem
                        to="declarations"
                        activeClassName={styles.active}
                      >
                        <Link id="declarations-nav" to="/declarations">
                          Декларації
                        </Link>
                      </NavItem>
                      <NavItem
                        to="pending-declarations"
                        activeClassName={styles.active}
                      >
                        <Link
                          id="pending-declarations-nav"
                          to="/pending-declarations"
                        >
                          Декларації на
                          <br /> розгляді
                        </Link>
                      </NavItem>
                    </ul>
                  </ShowMore>
                </li>
              </ShowWithScope>

              <ShowWithScope scope="employee:read">
                <li>
                  <ShowMore nav name="Співробітники">
                    <ShowWithScope scope="employee_request:read">
                      <ul>
                        <NavItem to="employees" activeClassName={styles.active}>
                          <Link id="employees-nav" to="/employees">
                            Співробітники
                          </Link>
                        </NavItem>
                        <NavItem
                          to="pending-employees"
                          activeClassName={styles.active}
                        >
                          <Link
                            id="pending-employees-nav"
                            to="/pending-employees"
                          >
                            Співробітники
                            <br />
                            на розгляді
                          </Link>
                        </NavItem>
                      </ul>
                    </ShowWithScope>
                  </ShowMore>
                </li>
              </ShowWithScope>
            </Flag>

            <NavItem to="reports" activeClassName={styles.active}>
              <Link id="reports-nav" to="/reports">
                Звіти
              </Link>
            </NavItem>

            <Flag name={["features", "legacy"]}>
              <ShowWithScope scope={["register:read", "register_entry:read"]}>
                <li>
                  <ShowMore nav name="Реєстри">
                    <ul>
                      <ShowWithScope scope="register:read">
                        <NavItem to="registers" activeClassName={styles.active}>
                          <Link id="registers-nav" to="/registers">
                            Реєстри
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                      <ShowWithScope scope="register_entry:read">
                        <NavItem
                          to="registers-entries"
                          activeClassName={styles.active}
                        >
                          <Link
                            id="registers-entries-nav"
                            to="/registers-entries"
                          >
                            Записи реєстру
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                    </ul>
                  </ShowMore>
                </li>
              </ShowWithScope>
            </Flag>

            <Flag name={["features", "legacy"]}>
              <ShowWithScope
                scope={["innm:read", "innm_dosage:read", "medication:read"]}
              >
                <li>
                  <ShowMore nav name="Медикаменти">
                    <ul>
                      <ShowWithScope scope="innm:read">
                        <NavItem to="innms" activeClassName={styles.active}>
                          <Link id="innms-nav" to="/innms">
                            МНН
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                      <ShowWithScope scope="innm_dosage:read">
                        <NavItem
                          to="innm-dosages"
                          activeClassName={styles.active}
                        >
                          <Link id="innm-dosages-nav" to="/innm-dosages">
                            Лікарська форма
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                      <ShowWithScope scope="medication:read">
                        <NavItem
                          to="medications"
                          activeClassName={styles.active}
                        >
                          <Link id="medications-nav" to="/medications">
                            Торгівельне <br /> найменування
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                    </ul>
                  </ShowMore>
                </li>
              </ShowWithScope>

              <ShowWithScope
                scope={["medical_program:read", "program_medication:read"]}
              >
                <li>
                  <ShowMore nav name="Програми">
                    <ul>
                      <ShowWithScope scope="medical_program:read">
                        <NavItem
                          to="medical-programs"
                          activeClassName={styles.active}
                        >
                          <Link
                            id="medical-programs-nav"
                            to="/medical-programs"
                          >
                            Перелік мед. програм
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                      <ShowWithScope scope="program_medication:read">
                        <NavItem
                          to="program-medications"
                          activeClassName={styles.active}
                        >
                          <Link
                            id="program_medications-nav"
                            to="/program-medications"
                          >
                            Учасники програм
                          </Link>
                        </NavItem>
                      </ShowWithScope>
                    </ul>
                  </ShowMore>
                </li>
              </ShowWithScope>
            </Flag>

            <ShowWithScope
              scope={[
                "medication_request_admin:read",
                "medication_dispense_admin:read",
                "reimbursement_report:download"
              ]}
            >
              <li>
                <ShowMore nav name="Рецепти">
                  <ul>
                    <ShowWithScope scope="medication_request_admin:read">
                      <NavItem
                        to="medication-requests"
                        activeClassName={styles.active}
                      >
                        <Link
                          id="medication-requests-nav"
                          to="/medication-requests"
                        >
                          Рецепти
                        </Link>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="medication_dispense_admin:read">
                      <NavItem
                        to="medication-dispenses"
                        activeClassName={styles.active}
                      >
                        <Link
                          id="medication-dispenses-nav"
                          to="/medication-dispenses"
                        >
                          Відпуски рецептів
                        </Link>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="reimbursement_report:download">
                      <NavItem
                        to="reimbursement-report"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="reimbursement-report-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/reimbursement-report`}
                        >
                          Звіт
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>
            <ShowWithScope scope={["bl_user:read", "party_user:read"]}>
              <li>
                <ShowMore nav name="Користувачі">
                  <ul>
                    <ShowWithScope scope="bl_user:read">
                      <NavItem
                        to="black-list-users"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="black_list_users"
                          href={`${env.REACT_APP_ADMIN_URL}/black-list-users`}
                        >
                          Заблоковані <br /> користувачі
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="party_user:read">
                      <NavItem to="party-users" activeClassName={styles.active}>
                        <ExternalLink
                          id="party_users"
                          href={`${env.REACT_APP_ADMIN_URL}/party-users`}
                        >
                          Облікові записи
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>
            <ShowWithScope scope={["contract:read", "contract_request:read"]}>
              <li>
                <ShowMore nav name="Договори">
                  <ul>
                    <ShowWithScope scope="contract:read">
                      <NavItem to="contracts" activeClassName={styles.active}>
                        <ExternalLink
                          id="contracts-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/contracts/capitation`}
                        >
                          Перелік договорів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="contract_request:read">
                      <NavItem
                        to="contract-requests"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="contract-requests-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/contract-requests/capitation`}
                        >
                          Заяви на укладення
                          <br /> договору
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope
              scope={["person:read", "person:reset_authentication_method"]}
            >
              <li>
                <ShowMore nav name="Паціенти">
                  <ul>
                    <ShowWithScope scope="person:read">
                      <NavItem to="persons" activeClassName={styles.active}>
                        <ExternalLink
                          id="persons-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/persons/search`}
                        >
                          Паціенти
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="person:reset_authentication_method">
                      <NavItem
                        to="reset-authentication-method"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="persons-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/reset-authentication-method`}
                        >
                          Скинути метод <br /> аутентифікації у<br /> паціентів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope
              scope={[
                "episode:clinical_monitor",
                "encounter:clinical_monitor",
                "observation:clinical_monitor",
                "condition:clinical_monitor",
                "procedure:clinical_monitor",
                "service_request:clinical_monitor",
                "immunization:clinical_monitor",
                "allergy_intolerance:clinical_monitor",
                "care_plan:clinical_monitor",
                "diagnostic_report:clinical_monitor",
                "medication_request:clinical_monitor",
                "medication_dispense:clinical_monitor"
              ]}
            >
              <NavItem to="clinical-monitoring" activeClassName={styles.active}>
                <ExternalLink
                  id="clinical-monitoring-nav"
                  href={`${env.REACT_APP_ADMIN_URL}/clinical-monitoring`}
                >
                  Клінічний моніторинг
                </ExternalLink>
              </NavItem>
            </ShowWithScope>

            <ShowWithScope scope="register:write">
              <NavItem to="registers" activeClassName={styles.active}>
                <ExternalLink
                  id="registers-nav"
                  href={`${env.REACT_APP_ADMIN_URL}/registers`}
                >
                  Реєстри
                </ExternalLink>
              </NavItem>
            </ShowWithScope>

            <ShowWithScope scope="legal_entity:read">
              <li>
                <ShowMore nav name="Заклади">
                  <ul>
                    <NavItem to="clinics" activeClassName={styles.active}>
                      <ExternalLink
                        id="clinics-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/legal-entities/search`}
                      >
                        Заклади
                      </ExternalLink>
                    </NavItem>
                    <ShowWithScope scope="legal_entity:merge">
                      <NavItem
                        to="clinics-reorganizations"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="clinics-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/legal-entities-reorganizations`}
                        >
                          Реорганізація закладів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope
              scope={["innm:read", "innm_dosage:read", "medication:read"]}
            >
              <li>
                <ShowMore nav name="Медикаменти">
                  <ul>
                    <ShowWithScope scope="innm:read">
                      <NavItem to="innms" activeClassName={styles.active}>
                        <ExternalLink
                          id="innms-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/innms/search`}
                        >
                          МНН
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="innm_dosage:read">
                      <NavItem
                        to="innm-dosages"
                        activeClassName={styles.active}
                      >
                        <Link
                          id="innm-dosages-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/innm-dosages/search`}
                        >
                          Лікарська форма
                        </Link>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="medication:read">
                      <NavItem to="medications" activeClassName={styles.active}>
                        <Link
                          id="medications-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/medications/search`}
                        >
                          Торгівельне <br /> найменування
                        </Link>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope scope="device_definition:read">
              <NavItem to="device-definitions" activeClassName={styles.active}>
                <ExternalLink
                  id="device_definition-nav"
                  href={`${env.REACT_APP_ADMIN_URL}/device-definitions`}
                >
                  Медичні вироби
                </ExternalLink>
              </NavItem>
            </ShowWithScope>

            <ShowWithScope
              scope={[
                "medical_program:read",
                "program_medication:read",
                "program_service:read",
                "program_device:read"
              ]}
            >
              <li>
                <ShowMore nav name="Медичні програми">
                  <ul>
                    <ShowWithScope scope="medical_program:read">
                      <NavItem
                        to="medical-programs"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="medical-programs-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/medical-programs/search`}
                        >
                          Медичні програми
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="program_medication:read">
                      <NavItem
                        to="program-medications"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="program_medications-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/program-medications/search`}
                        >
                          Учасники медичних <br /> програм з видачі <br />{" "}
                          медикаментів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="program_service:read">
                      <NavItem
                        to="program-services"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="program-services-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/program-services/search`}
                        >
                          Учасники медичних <br /> програм з надання <br />{" "}
                          послуг
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="program_device:read">
                      <NavItem
                        to="program-devices"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="program-devices-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/program-devices`}
                        >
                          Учасники медичних <br /> програм з видачі <br />{" "}
                          медичних виробів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope
              scope={["composition_admin:read", "composition_admin:write"]}
            >
              <li>
                <ShowMore nav name="Медичні висновки">
                  <ul>
                    <ShowWithScope scope="composition_admin:read">
                      <NavItem
                        to="medical-conclusions"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="medical-conclusions-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/medical-composition/search`}
                        >
                          Пошук МВ
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope scope="service_catalog:read">
              <li>
                <ShowMore nav name="Сервіси">
                  <ul>
                    <NavItem to="services" activeClassName={styles.active}>
                      <ExternalLink
                        id="services-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/services/search`}
                      >
                        Сервіси
                      </ExternalLink>
                    </NavItem>
                    <NavItem
                      to="service-groups"
                      activeClassName={styles.active}
                    >
                      <ExternalLink
                        id="service-groups-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/service-groups/search`}
                      >
                        Групи сервісів
                      </ExternalLink>
                    </NavItem>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope scope="declaration:read">
              <li>
                <ShowMore nav name="Декларації">
                  <ul>
                    <NavItem to="declarations" activeClassName={styles.active}>
                      <ExternalLink
                        id="declarations-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/declarations/search`}
                      >
                        Декларації
                      </ExternalLink>
                    </NavItem>
                    <NavItem
                      to="pending-declarations"
                      activeClassName={styles.active}
                    >
                      <ExternalLink
                        id="pending-declarations-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/pending-declarations/search`}
                      >
                        Декларації
                        <br />
                        на розгляді
                      </ExternalLink>
                    </NavItem>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope
              scope={[
                "legal_entity_merge_job:read",
                "legal_entity_deactivation_job:read",
                "persons_auth_reset_job:read",
                "persons_deactivation_job:read",
                "medication_registry_job:read"
              ]}
            >
              <li>
                <ShowMore
                  nav
                  name={
                    <>
                      Задачі в процесі
                      <br />
                      виконання
                    </>
                  }
                >
                  <ul>
                    <ShowWithScope scope="legal_entity_merge_job:read">
                      <NavItem
                        to="legal-entity-merge-jobs"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="legal-entity-merge-jobs-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/legal-entity-merge-jobs/search/related`}
                        >
                          Підпорядкування
                          <br /> закладів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="legal_entity_deactivation_job:read">
                      <NavItem
                        to="legal-entity-deactivate-jobs"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="legal-entity-deactivate-jobs-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/legal-entity-deactivate-jobs/search`}
                        >
                          Закриття
                          <br /> закладів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="persons_auth_reset_job:read">
                      <NavItem
                        to="reset-persons-auth-method-jobs"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="legal-entity-deactivate-jobs-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/reset-persons-auth-method-jobs/search`}
                        >
                          Скинути метод <br /> аутентифікації у<br /> паціентів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="persons_deactivation_job:read">
                      <NavItem
                        to="persons-deactivation-jobs"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="persons-deactivation-jobs-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/persons-deactivation-jobs/search`}
                        >
                          Деактивація <br />
                          паціентів
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <ShowWithScope scope="declarations_termination_job:read">
                      <NavItem
                        to="declarations-termination-jobs"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="declarations-termination-jobs-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/declarations-termination-jobs/search`}
                        >
                          Розірвання <br />
                          декларацій
                        </ExternalLink>
                      </NavItem>
                    </ShowWithScope>
                    <Flag name={["features", "medicationRegistryJobs"]}>
                      <ShowWithScope scope="medication_registry_job:read">
                        <NavItem
                          to="medication-registry-jobs"
                          activeClassName={styles.active}
                        >
                          <ExternalLink
                            id="medication-registry-jobs-nav"
                            href={`${env.REACT_APP_ADMIN_URL}/medication-registry-jobs/search`}
                          >
                            Завантаження реєстрів <br /> медикаментів
                          </ExternalLink>
                        </NavItem>
                      </ShowWithScope>
                    </Flag>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope scope="person_merge:read">
              <li>
                <ShowMore nav name="Об’єднання пацієнтів">
                  <ul>
                    <NavItem
                      to="applications-patient-merge-requests"
                      activeClassName={styles.active}
                    >
                      <ExternalLink
                        id="applications-patient-merge-requests-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/applications-patient-merge-requests/search`}
                      >
                        Заяви на об’єднання
                      </ExternalLink>
                    </NavItem>
                    <NavItem
                      to="applications-patient-merge-requests"
                      activeClassName={styles.active}
                    >
                      <ExternalLink
                        id="candidates-patient-merge-requests-nav"
                        href={`${env.REACT_APP_ADMIN_URL}/candidates-patient-merge-requests/search`}
                      >
                        Кандидати на об’єднання
                      </ExternalLink>
                    </NavItem>
                  </ul>
                </ShowMore>
              </li>
            </ShowWithScope>

            <ShowWithScope scope="employee:read">
              <li>
                <ShowMore nav name="Співробітники">
                  <ShowWithScope scope="employee_request:read">
                    <ul>
                      <NavItem to="employees" activeClassName={styles.active}>
                        <ExternalLink
                          id="employees-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/employees/search`}
                        >
                          Співробітники
                        </ExternalLink>
                      </NavItem>
                      <NavItem
                        to="employee-requests"
                        activeClassName={styles.active}
                      >
                        <ExternalLink
                          id="employee-requests-nav"
                          href={`${env.REACT_APP_ADMIN_URL}/employee-requests/search`}
                        >
                          Співробітники
                          <br />
                          на розгляді
                        </ExternalLink>
                      </NavItem>
                    </ul>
                  </ShowWithScope>
                </ShowMore>
              </li>
            </ShowWithScope>

            <Flag name={["features", "legacy"]}>
              <ShowWithScope scope="global_parameters:read">
                <NavItem to="configuration" activeClassName={styles.active}>
                  <ExternalLink
                    id="configuration-nav"
                    href={`${env.REACT_APP_ADMIN_URL}/configuration`}
                  >
                    Конфігурація системи
                  </ExternalLink>
                </NavItem>
              </ShowWithScope>
            </Flag>

            <ShowWithScope scope="global_parameters:read">
              <NavItem to="configuration" activeClassName={styles.active}>
                <Link id="configuration-nav" to="/configuration">
                  Конфігурація системи
                </Link>
              </NavItem>
            </ShowWithScope>

            <NavItem to="dictionaries" activeClassName={styles.active}>
              <ExternalLink
                id="dictionaries-nav"
                href={`${env.REACT_APP_ADMIN_URL}/dictionaries/search`}
              >
                Словники
              </ExternalLink>
            </NavItem>

            <Flag name={["features", "diagnosesGroups"]}>
              <ShowWithScope scope="diagnoses_group:read">
                <NavItem to="diagnoses-groups" activeClassName={styles.active}>
                  <ExternalLink
                    id="diagnoses-groups-nav"
                    href={`${env.REACT_APP_ADMIN_URL}/diagnoses-groups`}
                  >
                    Групи діагнозів
                  </ExternalLink>
                </NavItem>
              </ShowWithScope>
            </Flag>

            <ShowWithScope scope="forbidden_group:details">
              <NavItem to="forbiddenGroups" activeClassName={styles.active}>
                <ExternalLink
                  id="forbiddenGroups-nav"
                  href={`${env.REACT_APP_ADMIN_URL}/forbiddenGroups`}
                >
                  Заборонені групи
                </ExternalLink>
              </NavItem>
            </ShowWithScope>

            <ShowWithScope scope="rule_engine_rule:details">
              <NavItem to="rule-engine" activeClassName={styles.active}>
                <ExternalLink
                  id="rule-engine-nav"
                  href={`${env.REACT_APP_ADMIN_URL}/rule-engine`}
                >
                  Набори механізмів правил
                </ExternalLink>
              </NavItem>
            </ShowWithScope>
          </ul>

          <ul className={styles.down}>
            <li>
              <a
                href="http://docs.uaehealthapi.apiary.io/#"
                rel="noopener noreferrer"
                target="_blank"
              >
                <DocIcon />
                Документація
              </a>
            </li>
            <li className={styles.logout} onClick={() => this.props.logOut()}>
              <LogoutIcon width={10} height={10} />
              Вийти
            </li>
          </ul>
        </nav>
      </FlagsProvider>
    );
  }
}

export default connect(
  (state) => ({
    location: state.routing
  }),
  { logOut }
)(Nav);
